<template>
  <div class="">
    <b-row class="px-2 d-flex justify-content-center" >
      <!-- Register-->
      <b-col
        lg="9"
        md="12"
        x="6"

      >
          <b-card-title class="mb-1">
           
            Create new FiqOn User:
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- fullname -->
              <b-form-group
                :label="$t('login.create_account.form.fullname')"
                label-for="register-fullname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fullname"
                  vid="Fullname"
                  rules="required"
                >
                  <b-form-input
                    id="register-fullname"
                    v-model="fullname"
                    name="register-fullname"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Fullname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group
                :label="$t('login.create_account.form.username')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('login.create_account.form.email')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="email@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                :label="$t('login.create_account.form.password')"
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                      @focus="passwordFocused=true"
                      @blur="passwordFocused=false"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>


              <b-collapse :visible="passwordFocused" appear>
                <div class="small text-secondary bg-darkened text-light border p-1 shadow mb-2 ">
                  <div align="center"><b>Password must include at least one of each:</b></div>
                  <div class="d-flex justify-content-between mt-50 w-75 mx-auto">
                    <ul class="m-0 px-1">
                      <li>Lowercase Character</li>
                      <li>Uppercase Character</li>
                    </ul>
                    <ul class="m-0 px-1">
                      <li>Special Character</li>
                      <li>Number</li>
                    </ul>
                  </div>
                </div>
              </b-collapse>

              <!-- phone -->
              <b-form-group
                :label="$t('login.create_account.form.phone')"
                label-for="register-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="register-phone"
                    v-model="phone"
                    name="register-phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="(00) 0 0000-0000"
                    @input="phoneMask"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="" v-if="userTypes">
                User Level:
                <v-select v-model="selectedUserLevel" :options="userTypes" ></v-select>
              </div>

              <div class="divider my-2">
                <b-link class="divider-text">
                  fique.online
                </b-link>
              </div>
              <div align="center">
                <b-button
                  variant="success"
                  class="py-50 px-3"
                  type="submit"
                >
                  <h5 class="font-weight-bolder m-0 text-black">Create User</h5>
                </b-button>
              </div>
            </b-form>
          </validation-observer>

      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText, 
  BCollapse,
  BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from '@/store/index'
import Animation from '/src/layouts/components/Animation.vue'
import { makeToast } from '@/layouts/components/Popups'
import custom from "@/custom";
import UserAccessLevels from '@/custom/class/Enum/UserAccessLevels'
import vSelect from 'vue-select'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    Animation,
    DarkToggler,
    BCollapse,
    BFormSelect,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      fullname: '',
      username: '',
      userEmail: '',
      phone: '',
      password: '',
      logoImg: require('@/assets/images/logo/logo.svg'),
      required,
      email,
      userTypes: undefined,
      passwordFocused: false,
      selectedUserLevel: new UserAccessLevels().items.find(el=>el.id == 3)
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return false
      }
      return true
    },
  },
  mounted () {
    let userTypes = new UserAccessLevels().items;
    userTypes = userTypes.filter(el => el.can_be_created)
    this.userTypes = userTypes
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          // useJwt.register({
          //   username: this.username,
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(response.data.userData))
          //     this.$ability.update(response.data.userData.ability)
          //     this.$router.push('/')
          //   })
          //   .catch(error => {
          //     this.$refs.registerForm.setErrors(error.response.data.error)
          //   })

          if (true) {
            this.$store.dispatch('userRegistration', 
              {
                username: this.username,
                password: this.password,
                email   : this.userEmail,
                phone   : this.phone,
                fullname: this.fullname, 
                access_level: this.selectedUserLevel.api_id
              }
            )
            .then(response => {
              console.log(response) 
  
              if(response.status == 200){
          
                this.$router.push({ name:'verify-email' }).then(() => {
                  makeToast(custom.successMessages.register)            
                })
              }else{
                makeToast(custom.errorMessages[response.data.code])
              }
            }).catch(error => {
              console.log('opa', error)
            })
          }else{
            makeToast(custom.errorMessages['agreeWithTerms'])
          }
        }
      })
    },
    async phoneMask(){
      /* let value = this.phone
      if (value.length <= 3){
        var re = new RegExp("^([0-9]{"+value.length+"})$");
        console.log('re 1 ->>', re)
        //return value = value.replace(re, '($1)');
      } 
      if (value.length > 3 && value.length <= 6){
        var re = new RegExp("^([0-9]{3})([0-9]{"+ (value.length - 3)+"})$");
        console.log('re 2 ->>', re)
        //return value = value.replace(re, '($1)-$2');
      }
      if (value.length > 6 && value.length <= 10){
        var re = new RegExp("^([0-9]{3})([0-9]{3})([0-9]{"+(value.length-6)+"})$");
        console.log('re 3 ->>', re)
        //return value = value.replace(re, '($1)-$2-$3');
      } */
      //return value;

      let value = await this.phone
      value = await value.replace(/\D/g,"")
      if (value.length <= 2){
        var re = new RegExp("^([0-9]{"+value.length+"})$");
        value = value.replace(re, '($1)');
        this.phone = value
      } 
      if (value.length > 2 && value.length <= 6){
        var re = new RegExp("^([0-9]{2})([0-9]{"+ (value.length - 2)+"})$");
        value = value.replace(re, '($1) $2');
        this.phone = value
      }
      if (value.length > 6 && value.length <= 10){
        var re = new RegExp("^([0-9]{2})([0-9]{4})([0-9]{"+(value.length-6)+"})$");
        value = value.replace(re, '($1) $2-$3');
        this.phone = value
      }
      if (value.length >= 11) {
        var re = new RegExp("^([0-9]{2})([0-9]{1})([0-9]{4})([0-9]{"+(value.length-7)+"})$");
        value = value.replace(re, '($1) $2 $3-$4')
        this.phone = value
      }
      this.phone = value
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .fiqon-logo-welcome{
    height: 17px;
  }
  .light-logo{
    filter: brightness(0) invert(1);
  }
  .brand-name{
    color: $primary;
  }
  .dark-light-icon{
    position:absolute;
    right:20px;
    top  :10px;
  }
  .bg-darkened{
    background-color: rgba(0, 0, 0, 0.15);
  }
</style>
