export default class UserAccessLevels {
  constructor() {
      this.items = [
        {
            "id": 1,
            "label": "GOD",
            "api_id": 666,
            "can_be_created": false,
        },
        {
            "id": 2,
            "label": "Spectator",
            "api_id": 2,
            "can_be_created": true,
        },
        {
          "id": 3,
          "label": "Developer",
          "api_id": 3,
          "can_be_created": true,
        },
      ]
  }
}