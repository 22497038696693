var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-row',{staticClass:"px-2 d-flex justify-content-center"},[_c('b-col',{attrs:{"lg":"9","md":"12","x":"6"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Create new FiqOn User: ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.fullname'),"label-for":"register-fullname"}},[_c('validation-provider',{attrs:{"name":"fullname","vid":"Fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-fullname","name":"register-fullname","state":errors.length > 0 ? false:null,"placeholder":"Fullname"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.username'),"label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"email@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.password'),"label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},on:{"focus":function($event){_vm.passwordFocused=true},"blur":function($event){_vm.passwordFocused=false}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-collapse',{attrs:{"visible":_vm.passwordFocused,"appear":""}},[_c('div',{staticClass:"small text-secondary bg-darkened text-light border p-1 shadow mb-2 "},[_c('div',{attrs:{"align":"center"}},[_c('b',[_vm._v("Password must include at least one of each:")])]),_c('div',{staticClass:"d-flex justify-content-between mt-50 w-75 mx-auto"},[_c('ul',{staticClass:"m-0 px-1"},[_c('li',[_vm._v("Lowercase Character")]),_c('li',[_vm._v("Uppercase Character")])]),_c('ul',{staticClass:"m-0 px-1"},[_c('li',[_vm._v("Special Character")]),_c('li',[_vm._v("Number")])])])])]),_c('b-form-group',{attrs:{"label":_vm.$t('login.create_account.form.phone'),"label-for":"register-phone"}},[_c('validation-provider',{attrs:{"name":"phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","name":"register-phone","state":errors.length > 0 ? false:null,"placeholder":"(00) 0 0000-0000"},on:{"input":_vm.phoneMask},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.userTypes)?_c('div',{},[_vm._v(" User Level: "),_c('v-select',{attrs:{"options":_vm.userTypes},model:{value:(_vm.selectedUserLevel),callback:function ($$v) {_vm.selectedUserLevel=$$v},expression:"selectedUserLevel"}})],1):_vm._e(),_c('div',{staticClass:"divider my-2"},[_c('b-link',{staticClass:"divider-text"},[_vm._v(" fique.online ")])],1),_c('div',{attrs:{"align":"center"}},[_c('b-button',{staticClass:"py-50 px-3",attrs:{"variant":"success","type":"submit"}},[_c('h5',{staticClass:"font-weight-bolder m-0 text-black"},[_vm._v("Create User")])])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }